import {
  ComponentRef,
  PageData,
  PageRef,
  TPAAppRef,
  TPAComp,
  TPAComponentType,
  TPACompRef,
  TPARef,
} from './types/common';
import { CHALLENGES_PAGES_OLD_TITLES, PAGE_TITLES } from './app-config';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

class EditorWrapper {
  constructor(
    private readonly editorSDK: FlowEditorSDK,
    private readonly token,
  ) {}

  // Only wix apps are allowed installed in this way
  async installTPA(appDefinitionId: string): Promise<TPAAppRef> {
    if (!(await this.isAppInstalled(appDefinitionId))) {
      return this.editorSDK.document.tpa.add.application(this.token, {
        appDefinitionId,
      });
    }
    // TODO: get pageRef by appId
    return Promise.resolve({ instanceId: appDefinitionId });
  }

  private installTPAComponent(compDescr: TPAComp): Promise<TPACompRef> {
    return this.editorSDK.document.tpa.add.component(this.token, compDescr);
  }

  async runAndWaitForApproval(cb): Promise<void> {
    return this.editorSDK.document.transactions.runAndWaitForApproval(
      this.token,
      cb,
    );
  }

  installTPAPage(appDefinitionId: string, pageId: string): Promise<TPACompRef> {
    const compDescr = {
      componentType: TPAComponentType.Page,
      appDefinitionId,
      page: {
        pageId,
      },
    };
    return this.installTPAComponent(compDescr);
  }

  async isAppInstalled(appDefinitionId: string) {
    return this.editorSDK.document.tpa.isApplicationInstalled(this.token, {
      appDefinitionId,
    });
  }

  async getAPI(appDefinitionId: string) {
    return this.editorSDK.application.getPublicAPI(this.token, {
      appDefinitionId,
    });
  }

  async isControllerAlreadyExist(controllerType: string) {
    const controllers = await this.editorSDK.controllers.listAllControllers(
      this.token,
    );
    for (const controller of controllers) {
      const data = await this.editorSDK.controllers.getData(
        this.token,
        controller,
      );
      if (data.type === controllerType) {
        return true;
      }
    }
    return false;
  }

  getMasterRef(): Promise<PageRef> {
    return this.editorSDK.siteSegments.getSiteStructure(this.token);
  }

  async setPageInitialTitles(pages: TPARef[], i18n): Promise<void> {
    await Promise.all(
      pages.map(async (page) => {
        if (
          PAGE_TITLES[page.tpaPageId] &&
          CHALLENGES_PAGES_OLD_TITLES[page.tpaPageId] === page.title
        ) {
          await this.updatePage(page.pageRef, {
            title: i18n.t(PAGE_TITLES[page.tpaPageId]),
          });
        } else if (!PAGE_TITLES[page.tpaPageId]) {
          console.warn(page.tpaPageId, 'Page title is not provided');
        }
      }),
    );
  }

  async addPagesToEditorPageMenu(pages: TPARef[]): Promise<void> {
    await Promise.all(
      pages.map(async (page) => {
        const { pageRef, tpaPageId } = page;
        const managingAppDefId = this.token;

        if (managingAppDefId !== page.managingAppDefId) {
          console.warn(
            'Page will be updated to Online Programs managing id',
            page,
          );

          await this.updatePage(pageRef, {
            managingAppDefId,
          });
        }
      }),
    );
  }

  async updatePage(pageRef, data: Partial<PageData>) {
    return this.editorSDK.document.pages.data.update(this.token, {
      pageRef,
      // @ts-ignore
      data: {
        ...data,
      },
    });
  }

  async openAppPage(pageId, appDefId) {
    // code is borrowed from Wix Forums, see
    // https://github.com/wix-private/app-market/blob/80fa930daf5076db9e41b6a49b4346da39a4652f/communities/communities-forum-app/src/assets/editor-script.js#L311
    const [{ applicationId }, allPages] = await Promise.all([
      this.getDataByAppDefId(appDefId),
      this.getAllPages(),
    ]);

    const page = allPages.find(
      ({ tpaPageId, tpaApplicationId }) =>
        tpaPageId === pageId && tpaApplicationId === applicationId,
    );

    if (page) {
      this.editorSDK.document.pages.navigateTo(this.token, {
        pageLink: { type: 'PageLink', pageId: page.id },
      });
    }
  }

  async openSP(compRef: ComponentRef, appDefId: string, widgetId: string) {
    const appData = await this.getDataByAppDefId(appDefId);
    const instance = await this.editorSDK.document.info.getAppInstance(
      this.token,
    );
    const locale = await this.editorSDK.environment.getLocale();
    const settingsUrl =
      // @ts-ignore
      appData.widgets[widgetId]?.settings?.url ||
      // @ts-ignore
      appData.widgets[widgetId]?.settings?.urlV2;

    if (!settingsUrl) {
      return;
    }

    return this.editorSDK.editor.openComponentPanel(this.token, {
      url: `${settingsUrl}?instance=${instance}&origCompId=${compRef.id}&viewMode=editor&compId=tpaSettings&locale=${locale}`,
      type: this.editorSDK.editor.PanelType.Settings,
      componentRef: compRef,
      initialData: {},
      width: 404,
      height: 528,
      title: 'Program List',
    });
  }

  getDataByAppDefId(appDefId) {
    return this.editorSDK.tpa.app.getDataByAppDefId(this.token, appDefId);
  }

  getAllPages() {
    return this.editorSDK.pages.data.getAll(this.token);
  }

  getAllComponents() {
    return this.editorSDK.components.getAllComponents(this.token);
  }

  getAllComponentsById(appId) {
    return this.editorSDK.document.tpa.app.getAllCompsByApplicationId(
      this.token,
      appId,
    );
  }

  getApplicationPages() {
    return this.editorSDK.document.pages.getApplicationPages(this.token);
  }

  getComponentRef(id: string) {
    return this.editorSDK.components.getById(this.token, { id });
  }

  getPage(compRef: ComponentRef): Promise<PageRef> {
    return this.editorSDK.components.getPage(this.token, {
      componentRef: compRef,
    });
  }

  getComponentData(componentRef: ComponentRef): Promise<any> {
    return this.editorSDK.components.data.get(this.token, {
      componentRef,
    });
  }

  getAppData<T>(appDefinitionId?: string) {
    return this.editorSDK.document.tpa.app.getDataByAppDefId(
      this.token,
      appDefinitionId || this.token,
    );
  }

  getLocale() {
    return this.editorSDK.environment.getLocale();
  }

  openDashboard(url: string) {
    return this.editorSDK.editor.openDashboardPanel(this.token, {
      url,
      closeOtherPanels: false,
    });
  }

  async highlightComponent(compRef: ComponentRef) {
    return this.editorSDK.editor.selection.locateAndHighlightComponentByCompRef(
      this.token,
      { compRef },
    );
  }

  async removeHighlightComponent() {
    return this.editorSDK.editor.selection.clearHighlights(this.token);
  }

  /**
   * Updates the state for one or more pages.
   * Use this functionality to give different edit experience to different pages by defining different
   * pages actions and settings in the appManifest.
   * https://github.com/wix-private/js-platform-editor-sdk/blob/0eac76ff130a1e884c45ef023cb7e5eac865de18/js/modules/document/pages/pages.ts#L150
   */
  setPageState(state: { [index: string]: PageRef[] }) {
    return this.editorSDK.document.pages.setState(this.token, {
      // @ts-ignore
      state,
    });
  }

  async addPage(
    pageId: string,
    options?: { managingAppDefId?: string; isHidden?: boolean },
  ) {
    const pageOptions: {
      pageId: string;
      title?: string;
      isHidden?: boolean;
      managingAppDefId?: string;
      shouldNavigate?: boolean;
    } = {
      pageId,
      shouldNavigate: false,
      isHidden: options?.isHidden ?? true,
    };

    if (options?.managingAppDefId) {
      pageOptions.managingAppDefId = options?.managingAppDefId;
    }

    await this.editorSDK.document.tpa.add.component(this.token, {
      componentType: this.editorSDK.document.tpa.TPAComponentType.Page,
      page: pageOptions,
    });
  }

  deletePages(challengesPages: TPARef[]) {
    return Promise.all(
      challengesPages.map(async (ref) => {
        await this.deletePage(ref);
      }),
    );
  }

  async getTpaSection(pageId: string, pages: TPARef[]) {
    const challengePage = pages.find((page) => page.tpaPageId === pageId);

    const appData = await this.getDataByAppDefId(this.token);

    const allComps = await this.getAllComponentsById(appData.applicationId);

    const pageTpaSection = allComps.find(
      (comp) => comp.pageId === challengePage?.pageRef?.id,
    );

    if (!pageTpaSection) {
      return null;
    }

    return pageTpaSection;
  }

  async getStyles(compRef) {
    return this.editorSDK.document.tpa.getStyleParams(this.token, {
      compRef,
    });
  }

  async setStyles(compRef, styleParams) {
    return this.editorSDK.document.tpa.setStyleParams(this.token, {
      compRef,
      styleParams,
    });
  }

  async getPublicData(compRef) {
    return this.editorSDK.document.tpa.data.getAll(this.token, { compRef });
  }

  async setPublicData(compRef, data: { key: string; value: any }) {
    return this.editorSDK.document.tpa.data.set(this.token, {
      compRef,
      key: data.key,
      value: data.value,
      scope: 'COMPONENT',
    });
  }

  async deleteComponent(ref) {
    return this.editorSDK.components.remove(this.token, { componentRef: ref });
  }

  async deletePage(ref) {
    return this.editorSDK.document.pages.remove(this.token, ref);
  }

  deleteApp() {
    return this.editorSDK.application.uninstall(this.token, {
      openConfirmation: true,
    });
  }

  async save() {
    return this.editorSDK.editor.save(this.token);
  }

  async delay(time = 3000) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
}

export default EditorWrapper;
